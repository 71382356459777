import React, { useEffect, useState } from "react";
import "../CSS/About.css";
import axios from "axios";
const api_address = process.env.REACT_APP_SERVER_API_ADDRESS;

const About = ({}) => {
	const [banner, setBanner] = useState();
	const [iconBanners, setIconBanners] = useState();
	useEffect(() => {
		document.title = "About - Hampden Furnishings";
	}, []);

	async function getBanners() {
		await axios
			.post(`${api_address}/content/getBanners`, {
				page: "about",
			})
			.then((response) => {
				setBanner(response.data.map((i) => i.image_link));
			});
		await axios
			.post(`${api_address}/content/getBanners`, {
				page: "about_icons",
			})
			.then((response) => {
				setIconBanners(response.data.map((i) => i.image_link));
			});
	}

	useEffect(() => {
		getBanners();
		// console.log(banners, 28);
	}, []);

	return (
		<div className="about-page">
			<img
				style={{ width: "100%", objectFit: "cover" }}
				src={`https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/${banner}`}
			></img>

			<h1>Hampden Furnishings</h1>
			<p>
				Inspired by the quintessential Baltimore, MD neighborhood, Hampden
				Furnishings produces furniture with the same quality and attention to
				detail that you’d find at Hampdens main drag, 36th Street aka “The
				Avenues” vintage shoppes and art galleries. Hampden Furnishings opened
				its doors, recognizing an unmet need in the furniture industry.<br></br>
				<br></br> We sought to build furniture that would perform through
				decades of wear and tear, pieces designed with you in mind. And so, with
				a few helping hands, we got to work. That’s right, everything we offer
				was built from scratch. It seemed the best way to take real ownership of
				the end product.<br></br>
				<br></br> The results were powerful and now, Hampden Furnishings outfits
				houses across the country. From ultra-breathable flex chairs to
				easy-expand tables, our products are innovative, solution-focused, and
				both durable and comfortable to the nth degree.<br></br>
				<br></br> We ship them out quickly because we know time is of the
				essence, and we do everything possible to give you, our customers, a
				smooth experience from first purchase to years down the line.
			</p>
			<div className="about-icons">
				{iconBanners?.map((banner) => {
					return (
						<div className="about-icon">
							<img
								src={`https://res.cloudinary.com/da3rom333/image/upload/${banner}`}
							></img>
						</div>
					);
				})}
				<div></div>
			</div>
			<div className="mas-cert">
				<img src="https://res.cloudinary.com/da3rom333/image/upload/v1700594935/Hampden%20Assets/banners/9cdba877-741f-42fd-b181-2d54912899de_vbn4lf.jpg"></img>
				<div className="mas-cert-info">
					<h2>MAS Certified Green®.</h2>
					<h3>Our entire product line is MAS Certified Green®.</h3>
					<h3>
						This logo is your assurance the product is certified for low VOC
						emissions.
					</h3>
					<button
						className="square-button-black"
						onClick={() => {
							window.open("https://mascertifiedgreen.com", "_blank");
						}}
					>
						Learn More
					</button>
				</div>
			</div>
		</div>
	);
};

export default About;
